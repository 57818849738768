import { CampaignDto } from '@components/ReferralBot/OwnCampaign/Type/CampaignDto'
import React from 'react'
import { t } from 'i18next'
import Box from '@mui/material/Box'
import { CampaignStatus } from '@components/ReferralBot/Type/CampaignStatus'
import { LoadingButton } from '@mui/lab'
import { getErrorMessage } from '@components/ReferralBot/helpers'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { cancelCampaign } from '@framework/api/referral-bot.api'
import { ConfirmDialog } from '@components/ReferralBot/Confirm'
import { useSnackbar } from 'notistack'
import CancelIcon from '@mui/icons-material/Cancel'

interface Props {
  campaign: CampaignDto
}

export const CancelContest = ({campaign}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient()
  const [confirmDialog, setConfirmDialog] = React.useState(false)
  const { mutate: cancelCampaignMutate, isPending: isCancellingCampaign } = useMutation({
    mutationFn: cancelCampaign,
  })

  const cancelCampaignClick = () => {
    setConfirmDialog(false)

    cancelCampaignMutate(campaign.uuid, {
      onSuccess: async () => {
        enqueueSnackbar(t('referralBot.newOwnCampaignForm.campaignCancelled'), { variant: 'success' })
        await queryClient.invalidateQueries({
          queryKey: ['campaign_status', campaign.uuid]
        })
        await queryClient.invalidateQueries({
          queryKey: ['campaigns', campaign.uuid]
        })
        await queryClient.invalidateQueries({
          queryKey: ['campaigns']
        })
      },
      onError: async (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
      },
    })
  }

  const cancellableStatuses = [
    CampaignStatus.ACTIVE,
    CampaignStatus.SCHEDULED,
  ];

  const canBeCancelled = cancellableStatuses.includes(campaign.status);

  return (
    <>
      {canBeCancelled && (
        <>
          <Box
            sx={{ p: 2 }}
            display="flex"
            justifyContent="center"
          >
            <LoadingButton
              loading={isCancellingCampaign}
              loadingPosition="start"
              startIcon={<CancelIcon />}
              variant="contained"
              color="error"
              onClick={() => setConfirmDialog(true)}
              disabled={isCancellingCampaign}
            >
              {t(`referralBot.newOwnCampaignForm.cancel`)}
            </LoadingButton>
          </Box>
          {confirmDialog && (
            <ConfirmDialog
              title={t('referralBot.newOwnCampaignForm.campaignCancelTitle') || undefined}
              handleConfirm={() => cancelCampaignClick()}
              handleCancel={() => setConfirmDialog(false)}
            />
          )}
        </>
      )}
    </>
  )
}
