import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { fetchCampaignWinners } from '@framework/api/referral-bot.api'
import { CampaignDto } from '@components/ReferralBot/OwnCampaign/Type/CampaignDto'

interface Props {
  campaign: CampaignDto
}

export const ContestWinners = ({campaign}: Props) => {
  const {
    data: winners,
    isPending: isLoadingCampaign,
  } = useQuery({
    queryKey: ['campaign_winners', campaign.uuid],
    queryFn: () => fetchCampaignWinners(campaign.uuid),
  })


  return (
    <>
      {winners && (
        <div>
          <h2>Winners</h2>
          <ul>
            {winners
              .sort((a, b) => a.winnerNumber - b.winnerNumber)
              .map((winner) => (
              <li key={winner.messengerId}>
                {winner.winnerNumber} {winner.name} {winner.username && (<>(@{winner.username})</>)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  )
}
