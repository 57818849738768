import React from 'react'
import ReactDOM from 'react-dom/client'

Settings.defaultZone = "utc";

import App from './App'

import './framework/i18n'
import './index.css'
import { Settings } from 'luxon'

if (import.meta.env.DEV) {
  import('@framework/mocks/browser').then(({ worker }) => {
    worker.start({ onUnhandledRequest: 'bypass' })
  })
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
