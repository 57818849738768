import React from 'react'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'
import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { CampaignStatusDtoFields } from '@components/ReferralBot/Type/CampaignStatusDto'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

import { CampaignStatusCompound, createStatusRows } from '../helpers/CampaignStatus'
import { CampaignType } from '@components/ReferralBot/Type/CampaignType'
import { isParticipantsVisible } from '@components/ReferralBot/helpers'
import Button from '@mui/material/Button'
import ShareIcon from '@mui/icons-material/Share'

interface Props {
  campaign: CampaignStatusCompound
  pickFields?: CampaignStatusDtoFields
}

export const StatusTable = ({ campaign, pickFields }: Props) => {
  const { enqueueSnackbar } = useSnackbar()

  // todo make it better
  if (pickFields === undefined && campaign?.type === CampaignType.CONTEST) {
    pickFields = ['name', 'type', 'status', 'startedAt', 'finishedAt', 'numberOfWinners']

    if (isParticipantsVisible(campaign.status)) {
      pickFields.push('numberOfParticipants');
    }
  }

  const rows = createStatusRows(campaign, pickFields)
  const copyLinkHandler = (link: string) => {
    return () => {
      navigator.clipboard.writeText(link)
      enqueueSnackbar(t('referralBot.copied'), { variant: 'success', autoHideDuration: 1000 })
    }
  }

  return (
    <>
      <Box sx={{ width: '100%', overflowX: 'auto' }}>
        <TableContainer component={Paper}>
          <Table
            aria-label="custom pagination table"
            sx={{ tableLayout: 'fixed' }}
          >
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.label}>
                  <TableCell
                    component="th"
                    scope="row"
                    width="120px"
                  >
                    {row.label}
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: 'bold' }}
                    align="left"
                  >
                    {row.copyable && (
                      <IconButton
                        aria-label="copy"
                        size="small"
                        onClick={copyLinkHandler(row.val)}
                      >
                        <ContentCopyIcon fontSize="inherit" />
                      </IconButton>
                    )}
                    {row.isLink ? (
                      <>
                        <a
                          href={row.val}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {row.valSecondary || row.val}
                        </a>
                        <br />
                        <br />
                        <Button
                          component="a"
                          href={`https://t.me/share/url?url=${encodeURIComponent(row.val)}&text=${encodeURIComponent(t('referralBot.campaign.shareText', {'campaignName': campaign.name}))}`}
                          target="_blank"
                          rel="noreferrer"
                          variant="outlined"
                          color="primary"
                          size="small"
                          startIcon={<ShareIcon />}
                        >
                          {t('referralBot.share')}
                        </Button>
                      </>
                    ) : (
                      <>{row.val}</>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}
