import { DateTime } from 'luxon'

export const formatDateTime = (date: string | null | Date | DateTime): string => {
  if (!date) {
    return ''
  }

  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  if (date instanceof DateTime) {
    return date.setZone(browserTimeZone).toLocaleString(DateTime.DATETIME_MED)
  }

  if (date instanceof Date) {
    return DateTime.fromJSDate(date).setZone(browserTimeZone).toLocaleString(DateTime.DATETIME_MED)
  }

  return DateTime.fromISO(date).setZone(browserTimeZone).toLocaleString(DateTime.DATETIME_MED)
}
export const toLuxonDateTime = (date: string | null | Date): DateTime | null => {
  if (!date) {
    return null
  }

  if (date instanceof Date) {
    return DateTime.fromJSDate(date)
  }

  return DateTime.fromISO(date)
}
