import { CampaignDto } from '@components/ReferralBot/OwnCampaign/Type/CampaignDto'
import { formatCurrency, isCampaignActive, isContest } from '@components/ReferralBot/helpers'
import { t } from 'i18next'
import { statusToTranslatableKey } from '@components/ReferralBot/helpers/CampaignStatus'
import * as React from 'react'

interface Props {
  campaign: CampaignDto
}

export const SecondaryDetails = ({campaign}: Props) => {
  const details: string[] = []

  details.push(`${t('referralBot.campaignsList.status')}: ${t(statusToTranslatableKey(campaign.status))}`)

  if (!isContest(campaign)) {
    details.push(`${t('referralBot.campaignsList.numberOfReferrers')}: ${campaign.numberOfReferrers}`)
    details.push(`${t('referralBot.campaignsList.numberOfReferrals')}: ${campaign.numberOfReferrals}`)
    details.push(`${t('referralBot.campaignsList.budget')}: ${formatCurrency(campaign.budget)}`)
  } else {
    // details.push(`${t('Winners')}: ${campaign.numberOfWinners}`)
    details.push(`${t('participants')}: ${campaign.numberOfParticipants ?? '0'}`)
  }

  return (
    <>
      {details.join(', ')}
    </>
  )
}
