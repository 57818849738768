import { t } from 'i18next'

import { CampaignStatus } from '@components/ReferralBot/Type/CampaignStatus'
import { CampaignStatusDto, CampaignStatusDtoFields } from '@components/ReferralBot/Type/CampaignStatusDto'
import { CampaignStatusRow } from '@components/ReferralBot/Type/CampaignStatusRow'
import { formatDateTime } from '@components/ReferralBot/helpers/date.helper'
import { formatCurrency } from '@components/ReferralBot/helpers/index'
import { CampaignType } from '@components/ReferralBot/Type/CampaignType'
import { CampaignDto } from '@components/ReferralBot/OwnCampaign/Type/CampaignDto'

export const statusToTranslatableKey = (status: CampaignStatus): string => {
  switch (status) {
    case CampaignStatus.INACTIVE:
      return 'referralBot.campaignStatus.inactive'
    case CampaignStatus.ACTIVE:
      return 'referralBot.campaignStatus.active'
    case CampaignStatus.FINISHED:
      return 'referralBot.campaignStatus.finished'
    case CampaignStatus.SCHEDULED:
      return 'referralBot.campaignStatus.scheduled'
    default:
      return ''
  }
}

export type CampaignStatusCompound = CampaignStatusDto | CampaignDto

export const createStatusRows = (campaignStatusDto: CampaignStatusCompound, fields?: CampaignStatusDtoFields): Array<CampaignStatusRow> => {
  const isContest = campaignStatusDto.type === CampaignType.CONTEST;

  console.log('campaignStatusDto', campaignStatusDto)

  const rows: CampaignStatusRow[] = [
    {
      label: t('referralBot.ownCampaignStatus.name'),
      val: campaignStatusDto.name,
      key: 'name',
    },
    {
      label: t('referralBot.ownCampaignStatus.type'),
      val: t('referralBot.campaign.shortType' + campaignStatusDto.type),
      key: 'type',
    },
    {
      label: t('referralBot.ownCampaignStatus.status'),
      val: t(statusToTranslatableKey(campaignStatusDto.status)),
      key: 'status',
    },
    {
      label: t('referralBot.ownCampaignStatus.rewardPerUser'),
      val: campaignStatusDto.rewardPerUser ? formatCurrency(campaignStatusDto.rewardPerUser.toString()) : '-',
      key: 'rewardPerUser',
    },
    {
      label: t('referralBot.ownCampaignStatus.budget'),
      val: campaignStatusDto.budget ? formatCurrency(campaignStatusDto.budget.toString()) : '-',
      key: 'budget',
    },
    {
      label: t('referralBot.ownCampaignStatus.numberOfReferrers'),
      val: String(campaignStatusDto.numberOfReferrers),
      key: 'numberOfReferrers',
    },
    {
      label: t('referralBot.ownCampaignStatus.numberOfReferrals'),
      val: String(campaignStatusDto.numberOfReferrals),
      key: 'numberOfReferrals',
    },
    {
      label: t(`referralBot.ownCampaignStatus.${isContest ? 'startsAt' : 'startedAt'}`),
      val: campaignStatusDto.startedAt ? formatDateTime(campaignStatusDto.startedAt) : '-',
      key: 'startedAt',
    },
    {
      label: t(`referralBot.ownCampaignStatus.${isContest ? 'finishesAt' : 'finishedAt'}`),
      val: campaignStatusDto.finishedAt ? formatDateTime(campaignStatusDto.finishedAt) : '-',
      key: 'finishedAt',
    },
  ]

  if ('budgetWallet' in campaignStatusDto) {
    rows.push({
        label: t('referralBot.ownCampaignStatus.budgetWallet'),
        val: campaignStatusDto.budgetWallet,
        key: 'budgetWallet',
        copyable: true,
      })
  }

  if ('budgetWallet' in campaignStatusDto && fields && fields.includes('fundLink')) {
    rows.push({
      label: t('referralBot.ownCampaignStatus.fundLink'),
      valSecondary: t('referralBot.ownCampaignStatus.fundLinkTitle') || '',
      val: `ton://transfer/${campaignStatusDto.budgetWallet}`,
      isLink: true,
      key: 'fundLink',
    })
  }

  if (isContest) {
    rows.push({
      label: t('referralBot.ownCampaignStatus.numberOfParticipants'),
      val: String(campaignStatusDto.numberOfParticipants || 0),
      key: 'numberOfParticipants',
    })
    rows.push({
      label: t('referralBot.ownCampaignStatus.numberOfWinners'),
      val: String(campaignStatusDto.numberOfWinners || 0),
      key: 'numberOfWinners',
    })
  }

  if ('joinLink' in campaignStatusDto && campaignStatusDto.status === CampaignStatus.ACTIVE) {
    rows.unshift({
      label: t('referralBot.ownCampaignStatus.joinLink'),
      val: campaignStatusDto.joinLink ? campaignStatusDto.joinLink : '-',
      isLink: Boolean(campaignStatusDto.joinLink),
      key: 'joinLink',
      copyable: true,
    })
  }

  if (fields) {
    return rows.filter((row) => fields.includes(row.key))
  }

  return rows
}
