import React, { useEffect } from 'react'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'
import { useAtom } from 'jotai/index'

import { useParams } from '@tanstack/react-router'
import {
  ownCampaignConnectBotRoute,
  ownCampaignFundRoute, ownCampaignSetBotUsernameRoute,
  ownCampaignStartRoute,
} from '@components/ReferralBot/OwnCampaign/router'
import { Button } from '@mui/material'
import { LinkButton } from '@components/LinkButton'
import Box from '@mui/material/Box'
import { useQuery } from '@tanstack/react-query'
import { fetchCampaign, fetchCampaignBudget } from '@framework/api/referral-bot.api'
import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import { LoadStatusTable } from '@components/ReferralBot/OwnCampaign/LoadStatusTable'
import SyncIcon from '@mui/icons-material/Sync'
import { getErrorMessage } from '@components/ReferralBot/helpers'
import { AlertMessage } from '@components/ReferralBot/Alert'
import { CampaignCreateStepper } from '@components/ReferralBot/OwnCampaign/Create/CampaignCreateStepper'
import { CampaignCreateStepperFooter } from '@components/ReferralBot/OwnCampaign/Create/CampaignCreateStepperFooter'
import { CampaignType } from '@components/ReferralBot/Type/CampaignType'
import { BOT_GUIDE_LINK, MANAGER_LINK } from '@framework/constants'

export const Fund = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { uuid } = useParams<typeof ownCampaignFundRoute>({
    from: ownCampaignFundRoute.id,
  })
  const {
    data: campaignBudget,
    isLoading: isLoadingCampaign,
    isRefetching,
    refetch: refetchCampaignBudget,
    error,
  } = useQuery({
    queryKey: ['campaign_budget', uuid],
    queryFn: () => fetchCampaignBudget(uuid),
  })
  const { data: campaign } = useQuery({
    queryKey: ['campaigns', uuid],
    queryFn: () => fetchCampaign(uuid),
  })

  if (error) {
    enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
  }

  const [, setTitle] = useAtom(headerTitleAtom)

  useEffect(() => {
    setTitle(t('referralBot.ownCampaigns.configureCampaign', {campaignName: campaignBudget?.name}) || '')

    return () => setTitle('')
  }, [campaignBudget])

  const displayFree = Number(campaignBudget?.budget) === 0

  return (
    <>
      {campaign && <CampaignCreateStepper activeStep={'fund_campaign'} campaign={campaign} />}
      {campaignBudget && (
        <AlertMessage
          severity="warning"
          text={t('referralBot.campaignFund.platformFeeExplanation', { platformFee: campaignBudget?.platformPercentFee })}
        />
      )}
      {displayFree && (
        <AlertMessage
          severity="info"
          text={<a target="_blank" href={MANAGER_LINK}>{t('referralBot.campaignFund.tryForFree')}</a>}
        />
      )}
      {campaignBudget && (
        <Box>
          <p>
            {t('referralBot.campaignFund.description')}
            <br />
            <b>{t('referralBot.campaignFund.minBudgetExplanation', { minBudget: campaignBudget.minBudgetInTON })}</b>
          </p>
        </Box>
      )}
      {!isRefetching && (
        <LoadStatusTable
          uuid={uuid}
          pickFields={['budget', 'budgetWallet', 'fundLink']}
        />
      )}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flex="1"
        p={3}
      >
        <Button
          startIcon={<SyncIcon />}
          variant="outlined"
          color="secondary"
          onClick={() => refetchCampaignBudget()}
          disabled={isLoadingCampaign || isRefetching}
        >
          {t('referralBot.campaignFund.refresh')}
        </Button>
      </Box>
      <CampaignCreateStepperFooter>
        <Button
          color="inherit"
          sx={{ mr: 1 }}
          component={LinkButton}
          to={campaignBudget?.type === CampaignType.BOT ? ownCampaignSetBotUsernameRoute.id : ownCampaignConnectBotRoute.id}
          params={{ uuid }}
        >
          {t('referralBot.back')}
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button
          sx={{ mr: 1 }}
          variant="contained"
          component={LinkButton}
          color="primary"
          to={ownCampaignStartRoute.id}
          params={{ uuid }}
        >
          {t('referralBot.next')}
        </Button>
      </CampaignCreateStepperFooter>
    </>
  )
}
