import React, { useEffect } from 'react'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'

import { Form, NewCampaignType } from '@components/ReferralBot/OwnCampaign/Form'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { fetchCampaign, pathCampaign } from '@framework/api/referral-bot.api'
import { useParams } from '@tanstack/react-router'
import {
  ownCampaignConnectBotRoute,
  ownCampaignEditBotRoute,
  ownCampaignSetBotUsernameRoute,
} from '@components/ReferralBot/OwnCampaign/router'
import { Box, Button } from '@mui/material'
import { LinkButton } from '@components/LinkButton'
import { getErrorMessage } from '@components/ReferralBot/helpers'
import { CampaignCreateStepper } from '@components/ReferralBot/OwnCampaign/Create/CampaignCreateStepper'
import { CampaignCreateStepperFooter } from '@components/ReferralBot/OwnCampaign/Create/CampaignCreateStepperFooter'
import { useAtom } from 'jotai/index'
import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import { CampaignStatus } from '@components/ReferralBot/Type/CampaignStatus'
import { CampaignType } from '@components/ReferralBot/Type/CampaignType'

export const Edit = () => {
  const { enqueueSnackbar } = useSnackbar()

  const {uuid} = useParams<typeof ownCampaignEditBotRoute>({
    from: ownCampaignEditBotRoute.id,
  })

  const { data: campaign, isLoading } = useQuery({
    queryKey: ['campaigns', uuid],
    queryFn: () => fetchCampaign(uuid),
  })
  const { mutate, isPending: isPatching } = useMutation({
    mutationKey: ['campaigns', uuid],
    mutationFn: pathCampaign,
  })
  const queryClient = useQueryClient()

  const [, setTitle] = useAtom(headerTitleAtom)

  useEffect(() => {
    setTitle(t('referralBot.ownCampaigns.configureCampaign', {campaignName: campaign?.name}) || '')

    return () => setTitle('')
  }, [campaign])

  const onSave = (payload: NewCampaignType) => {
    mutate(
      { ...payload, uuid },
      {
        onSuccess: async () => {
          enqueueSnackbar(t('referralBot.updated'), { variant: 'success' })
          await queryClient.invalidateQueries({ queryKey: ['campaigns'] })
          await queryClient.invalidateQueries({ queryKey: ['campaigns', uuid] })
        },
        onError: async (error) => {
          enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
        },
      }
    )
  }

  const dataExists = Boolean(campaign?.name && campaign?.rewardPerUser)

  const disableNextStep = isLoading || isPatching || !dataExists

  return (
    <>
      {campaign && (
        <>
          <CampaignCreateStepper activeStep={'base_info'} campaign={campaign} />
          <Form
            loading={isPatching}
            onSave={onSave}
            disabled={campaign.status === CampaignStatus.FINISHED}
            data={{
              name: campaign.name,
              rewardPerUser: Number(campaign.rewardPerUser),
            }}
          />
          <CampaignCreateStepperFooter>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button
              sx={{ mr: 1 }}
              variant="contained"
              component={LinkButton}
              color="primary"
              to={campaign.type === CampaignType.BOT ? ownCampaignSetBotUsernameRoute.id : ownCampaignConnectBotRoute.id}
              params={{ uuid: campaign.uuid }}
              disabled={disableNextStep}
            >
              {t('referralBot.next')}
            </Button>
          </CampaignCreateStepperFooter>
        </>
      )}
    </>
  )
}
