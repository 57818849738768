import React from 'react'

import { useQuery } from '@tanstack/react-query'
import { fetchCampaignStatus } from '@framework/api/referral-bot.api'
import { CampaignStatusDtoFields } from '@components/ReferralBot/Type/CampaignStatusDto'
import { StatusTable } from '@components/ReferralBot/OwnCampaign/StatusTable'

interface Props {
  uuid: string
  pickFields?: CampaignStatusDtoFields
}

export const LoadStatusTable = ({ uuid, pickFields }: Props) => {
  const { data: campaignStatusDto } = useQuery({
    queryKey: ['campaign_status', uuid],
    queryFn: () => fetchCampaignStatus(uuid),
  })

  return (
    <>
      {campaignStatusDto && (
        <StatusTable campaign={campaignStatusDto} pickFields={pickFields} />
      )}
    </>
  )
}
