import * as React from 'react'
import { ReferrerJoinedCampaign } from '@components/ReferralBot/Type/ReferrerJoinedCampaign'
import { CampaignTypeLabel } from '@components/ReferralBot/Campaign/CampaignTypeLabel'

interface Props {
  campaign: ReferrerJoinedCampaign
}

export const CampaignTitle = ({campaign}: Props) => {
  return (
    <>
      {campaign.campaignName}
      {' '}
      <CampaignTypeLabel type={campaign.campaignType} />
    </>
  )
}
