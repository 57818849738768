import React, { useEffect } from 'react'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'

import { useParams } from '@tanstack/react-router'
import {
  ownCampaignEditBotRoute,
  ownCampaignFundRoute,
  ownCampaignSetBotUsernameRoute,
} from '@components/ReferralBot/OwnCampaign/router'
import { Button, FormHelperText, Grid, TextField } from '@mui/material'
import { LinkButton } from '@components/LinkButton'
import Box from '@mui/material/Box'
import { useMutation, useQuery } from '@tanstack/react-query'
import { fetchCampaign, pathCampaignBot } from '@framework/api/referral-bot.api'
import { CampaignCreateStepper } from '@components/ReferralBot/OwnCampaign/Create/CampaignCreateStepper'
import { CampaignCreateStepperFooter } from '@components/ReferralBot/OwnCampaign/Create/CampaignCreateStepperFooter'
import * as z from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import SaveIcon from '@mui/icons-material/Save'
import { getErrorMessage } from '@components/ReferralBot/helpers'
import { useHeaderTitleHook } from '@components/ReferralBot/Hook/TitleHook'

const validationSchema = z.object({
  botUsername: z.string().min(1).max(50).regex(/^[a-zA-Z0-9_]+bot$/i),
})

export type UpdateCampaignBotType = z.infer<typeof validationSchema>

export const SetBotUsername = () => {
  const { enqueueSnackbar } = useSnackbar()
  const {uuid} = useParams<typeof ownCampaignSetBotUsernameRoute>({
    from: ownCampaignSetBotUsernameRoute.id
  })
  const {
    data: campaign,
    isLoading: isLoadingCampaign,
    refetch,
    isRefetching: isCampaignRefetching,
  } = useQuery({
    queryKey: ['campaigns', uuid],
    queryFn: () => fetchCampaign(uuid),
  })
  const { mutate, isPending: isPatching } = useMutation({
    mutationKey: ['campaigns_bot', uuid],
    mutationFn: pathCampaignBot,
  })

  const setHeaderTitle = useHeaderTitleHook();

  useEffect(() => {
    setHeaderTitle(t('referralBot.ownCampaigns.configureCampaign', {campaignName: campaign?.name}) || '')
  }, [campaign])

  const defaultData: UpdateCampaignBotType = {
    botUsername: campaign?.botUsername || '',
  }

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<UpdateCampaignBotType>({
    defaultValues: defaultData,
    resolver: zodResolver(validationSchema),
  })

  if (Object.keys(errors).length > 0) {
    console.debug('Validation errors: ', errors)
  }

  useEffect(() => {
    reset(defaultData);
  }, [campaign, reset]);

  const onSave = (payload: UpdateCampaignBotType) => {
    mutate(
      { ...payload, uuid },
      {
        onSuccess: async () => {
          enqueueSnackbar(t('referralBot.updated'), { variant: 'success' })
          await refetch()
        },
        onError: async (error) => {
          enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
        },
      }
    );
  }

  const disabled = isLoadingCampaign || isPatching || isCampaignRefetching;

  return (
    <>
      {campaign && (
        <>
          <CampaignCreateStepper activeStep={'connect_bot'} campaign={campaign} />
          <form
            onSubmit={handleSubmit(onSave)}
            id="create-own-campaign"
            aria-disabled={disabled}
          >
            <Grid
              item
              xs={12}
            >
              <Controller
                name="botUsername"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    id="botUsername"
                    label={t('referralBot.newOwnCampaignForm.botUsername')}
                    variant="outlined"
                    error={!!errors.botUsername}
                    helperText={String(errors.botUsername?.message || '')}
                    disabled={disabled}
                  />
                )}
              />
              <FormHelperText>
                {t('referralBot.newOwnCampaignForm.botUsernameHelper')}
              </FormHelperText>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ textAlign: 'center', mt: 2 }}
            >
              <LoadingButton
                loading={isPatching}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined"
                type="submit"
                disabled={disabled}
              >
                {t(`referralBot.save`)}
              </LoadingButton>
            </Grid>
          </form>
          <CampaignCreateStepperFooter>
            <Button
              color="inherit"
              sx={{ mr: 1 }}
              component={LinkButton}
              to={ownCampaignEditBotRoute.id}
              params={{ uuid: campaign.uuid }}
            >
              {t('referralBot.back')}
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button
              sx={{ mr: 1 }}
              variant="contained"
              component={LinkButton}
              color="primary"
              to={ownCampaignFundRoute.id}
              params={{ uuid: campaign.uuid }}
              disabled={!campaign.botUsername}
            >
              {t('referralBot.next')}
            </Button>
          </CampaignCreateStepperFooter>
        </>
      )}
    </>
  )
}
