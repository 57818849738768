import { MainContainer } from '@components/ReferralBot/MainContainer'
import { rootRoute } from '@components/Site/siteRouter'
import { Route } from '@tanstack/react-router'
import {
  ownCampaignAddBotRoute,
  ownCampaignConnectBotRoute,
  ownCampaignEditBotRoute,
  ownCampaignEditContestBaseInfoBotRoute,
  ownCampaignFundRoute,
  ownCampaignListBotRoute,
  ownCampaignSetBotUsernameRoute,
  ownCampaignStartRoute,
  ownCampaignStatusRoute,
} from '@components/ReferralBot/OwnCampaign/router'
import { joinedCampaignDetailsRoute, joinedCampaignListBotRoute } from '@components/ReferralBot/JoinedCampaign/router'
import { paymentBalanceBotRoute, paymentWithdrawBalanceBotRoute } from '@components/ReferralBot/Payment/router'
import { homeScreenBotRoute } from '@components/ReferralBot/Home/router'
import { meCampaignDetailsBotRoute, meCampaignsListBotRoute } from '@components/ReferralBot/Me/router'

export const referralBotRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/referral-bot',
  component: MainContainer,
})
referralBotRoute.addChildren([
  ownCampaignListBotRoute,
  ownCampaignAddBotRoute,
  ownCampaignEditBotRoute,
  ownCampaignConnectBotRoute,
  ownCampaignStatusRoute,
  joinedCampaignListBotRoute,
  joinedCampaignDetailsRoute,
  ownCampaignStartRoute,
  ownCampaignFundRoute,
  paymentBalanceBotRoute,
  paymentWithdrawBalanceBotRoute,
  homeScreenBotRoute,
  meCampaignsListBotRoute,
  meCampaignDetailsBotRoute,
  ownCampaignSetBotUsernameRoute,
  ownCampaignEditContestBaseInfoBotRoute,
])
