import React from 'react'
import { t } from 'i18next'
import { CampaignType, getCampaignTypeValues } from '@components/ReferralBot/Type/CampaignType'
import { FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { BOT_GUIDE_LINK } from '@framework/constants'
import { LoadingButton } from '@mui/lab'
import SaveIcon from '@mui/icons-material/Save'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'

const validationSchema = z.object({
  type: z.coerce.number(), // use enum?
})

export type CampaignTypePayload = z.infer<typeof validationSchema>

interface Props {
  isBotTypeDisabled?: boolean
  loading: boolean
  disabled?: boolean
  onSave: (payload: CampaignTypePayload) => void
}

export const CampaignTypeStep = ({ isBotTypeDisabled, loading, disabled, onSave }: Props) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CampaignTypePayload>({
    defaultValues: {type: CampaignType.CONTEST},
    resolver: zodResolver(validationSchema),
  })

  return (
    <>
      <form
        onSubmit={handleSubmit(onSave)}
        id="create-own-campaign"
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <FormLabel>{t(`referralBot.newOwnCampaignForm.chooseCampaignType`)}</FormLabel>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <>
                  <RadioGroup
                    {...field}
                  >
                    {getCampaignTypeValues().map((type) => (
                      <FormControlLabel
                        disabled={isBotTypeDisabled && type === CampaignType.BOT}
                        key={type}
                        value={type}
                        control={<Radio />}
                        label={t(`referralBot.newOwnCampaignForm.type_${type}`)}
                      />
                    ))}
                  </RadioGroup>
                  {errors.type?.message && <FormHelperText error>{errors.type.message}</FormHelperText>}
                </>
              )}
            />
            {isBotTypeDisabled && (
              <FormHelperText>
                <a href={BOT_GUIDE_LINK} target="_blank">{t(`referralBot.newOwnCampaignForm.learnHowToConnectBot`)}</a>
              </FormHelperText>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ textAlign: 'center' }}
          >
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
              type="submit"
              disabled={disabled}
            >
              {t(`referralBot.save`)}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </>
  )
}
