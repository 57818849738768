import React from 'react'
import { t } from 'i18next'
import { useAtom } from 'jotai/index'

import { useParams } from '@tanstack/react-router'
import { Button } from '@mui/material'
import { LinkButton } from '@components/LinkButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {ownCampaignListBotRoute, ownCampaignStatusRoute} from '@components/ReferralBot/OwnCampaign/router'
import { TopActions } from '@components/ReferralBot/TopActions'
import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import { LoadStatusTable } from '@components/ReferralBot/OwnCampaign/LoadStatusTable'
import { useQuery } from '@tanstack/react-query'
import { fetchCampaign } from '@framework/api/referral-bot.api'
import { StatusTable } from '@components/ReferralBot/OwnCampaign/StatusTable'
import { isCampaignFinished, isContest } from '@components/ReferralBot/helpers'
import { CancelContest } from '@components/ReferralBot/OwnCampaign/Create/Contest/CancelContest'
import { ContestWinners } from '@components/ReferralBot/OwnCampaign/Contest/ContestWinners'
import { CancelReferral } from '@components/ReferralBot/OwnCampaign/Create/Referral/CancelReferral'

export const Status = () => {
  const { uuid } = useParams<typeof ownCampaignStatusRoute>({
    from: ownCampaignStatusRoute.id,
  })
  const [, setTitle] = useAtom(headerTitleAtom)
  setTitle(t('referralBot.ownCampaignStatus.title') || '')

  const {
    data: campaign,
    isPending: isLoadingCampaign,
  } = useQuery({
    queryKey: ['campaigns', uuid],
    queryFn: () => fetchCampaign(uuid),
  })


  return (
    <>
      <TopActions>
        <Button
          component={LinkButton}
          startIcon={<ArrowBackIcon />}
          to={ownCampaignListBotRoute.id}
          variant="outlined"
          color="primary"
        >
          {t('referralBot.createCampaignSteps.backToCampaigns')}
        </Button>
      </TopActions>
      {campaign && <StatusTable campaign={campaign} />}
      {campaign && isContest(campaign) && <CancelContest campaign={campaign} />}
      {campaign && !isContest(campaign) && <CancelReferral campaign={campaign} />}
      {campaign && isContest(campaign) && isCampaignFinished(campaign) && <ContestWinners campaign={campaign} />}
    </>
  )
}
