import React, { useEffect } from 'react'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createCampaign } from '@framework/api/referral-bot.api'
import { useNavigate } from '@tanstack/react-router'
import {
  ownCampaignEditBotRoute,
  ownCampaignEditContestBaseInfoBotRoute, ownCampaignListBotRoute,
} from '@components/ReferralBot/OwnCampaign/router'
import { getErrorMessage } from '@components/ReferralBot/helpers'
import { useAtom } from 'jotai/index'
import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import { CampaignType } from '@components/ReferralBot/Type/CampaignType'
import { CampaignTypePayload, CampaignTypeStep } from '@components/ReferralBot/OwnCampaign/Create/CampaignTypeStep'
import { TopActions } from '@components/ReferralBot/TopActions'
import { Button } from '@mui/material'
import { LinkButton } from '@components/LinkButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export const Add = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const { mutate, isPending: isCreating } = useMutation({
    mutationFn: createCampaign,
  })
  const [, setTitle] = useAtom(headerTitleAtom)

  useEffect(() => {
    setTitle(t('referralBot.ownCampaigns.createCampaign'))
    return () => setTitle('')
  }, [])

  const navigate = useNavigate()
  const onSave = (payload: CampaignTypePayload) => {
    mutate(payload, {
      onSuccess: async (data) => {
        enqueueSnackbar(t('referralBot.newOwnCampaignForm.created'), { variant: 'success' })

        await queryClient.invalidateQueries({
          queryKey: ['campaigns']
        })

        if (data.type === CampaignType.CONTEST) {
          await navigate({ to: ownCampaignEditContestBaseInfoBotRoute.id, params: { uuid: data.uuid } })
        } else {
          await navigate({ to: ownCampaignEditBotRoute.id, params: { uuid: data.uuid } })
        }
      },
      onError: async (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
      },
    })
  }

  return (
    <>
      <TopActions>
        <Button
          component={LinkButton}
          startIcon={<ArrowBackIcon />}
          to={ownCampaignListBotRoute.id}
          variant="outlined"
          color="primary"
        >
          {t('referralBot.createCampaignSteps.backToCampaigns')}
        </Button>
      </TopActions>
      <CampaignTypeStep
        loading={isCreating}
        onSave={onSave}
        isBotTypeDisabled={true}
      />
    </>
  )
}
