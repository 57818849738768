import { CampaignDto } from '@components/ReferralBot/OwnCampaign/Type/CampaignDto'
import React from 'react'
import { CancelContest } from '@components/ReferralBot/OwnCampaign/Create/Contest/CancelContest'
import { StatusTable } from '@components/ReferralBot/OwnCampaign/StatusTable'
import { isCampaignInactive, isValidStartTime } from '@components/ReferralBot/helpers'
import { Alert } from '@mui/material'
import { t } from 'i18next'
import { MIN_MINUTEST_TO_START_CONTEST } from '@components/ReferralBot/Constant'
import { Link } from '@tanstack/react-router'
import { ownCampaignEditContestBaseInfoBotRoute } from '@components/ReferralBot/OwnCampaign/router'
import Box from '@mui/material/Box'

interface Props {
  campaign: CampaignDto
}

export const StartContestCampaignStep = ({campaign}: Props) => {
  const invalidStartTime = !isValidStartTime(campaign) && isCampaignInactive(campaign.status)

  return (
    <>
      {invalidStartTime && (
        <Alert severity="warning">
          {t('referralBot.newOwnCampaignForm.startTimeInvalid', {minutes: MIN_MINUTEST_TO_START_CONTEST})}
          {' '}
          <Link to={ownCampaignEditContestBaseInfoBotRoute.id} params={{
            uuid: campaign.uuid,
          }}>
            {t('referralBot.edit')}
          </Link>
        </Alert>
      )}
      <Box pt={2} pb={2}>
        <Alert severity={'info'}>
          {t('referralBot.newOwnCampaignForm.contestWinnerRule1')}
        </Alert>
      </Box>
      <StatusTable campaign={campaign} />
      <CancelContest campaign={campaign} />
    </>
  )
}
