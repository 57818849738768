import * as React from 'react'
import { t } from 'i18next'

import List from '@mui/material/List'
import { Button, Divider, ListItem, ListItemSecondaryAction } from '@mui/material'
import ListItemText from '@mui/material/ListItemText'
import Box from '@mui/material/Box'
import { statusToTranslatableKey } from '@components/ReferralBot/helpers/CampaignStatus'
import { VertMenu } from '@components/ReferralBot/VertMenu'
import MenuItem from '@mui/material/MenuItem'
import { LinkButton } from '@components/LinkButton'
import { ReferrerJoinedCampaign } from '@components/ReferralBot/Type/ReferrerJoinedCampaign'
import { joinedCampaignDetailsRoute } from '@components/ReferralBot/JoinedCampaign/router'
import InfoIcon from '@mui/icons-material/Info'
import { canWithdrawReward } from '@components/ReferralBot/helpers/referrer.helper'
import { CampaignTitle } from '@components/ReferralBot/JoinedCampaign/CampaignTitle'

interface Props {
  campaigns: ReferrerJoinedCampaign[]
}

export function ListAsTable({ campaigns }: Props) {
  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {campaigns.map((item) => (
        <Box key={item.campaignName}>
          <ListItem>
            <ListItemText
              primary={<CampaignTitle campaign={item} />}
              secondary={
                <>
                  {t('referralBot.campaignsList.status')}: {t(statusToTranslatableKey(item.campaignStatus))},{' '}
                  {t('referralBot.joinedCampaigns.numberOfReferrals')}: {item.numberOfReferrals}, {t('referralBot.joinedCampaigns.reward')}:{' '}
                  {`~${item.reward}`}
                  {item.rewardWithdrewAt && `, ${t('referralBot.joinedCampaigns.rewardWithdrew')}`}
                  {canWithdrawReward(item.reward, item.campaignFinishedAt, item.rewardWithdrewAt) &&
                    `, ${t('referralBot.joinedCampaigns.canWithdraw')}`}
                </>
              }
            />
            <ListItemSecondaryAction>
              <VertMenu>
                <MenuItem>
                  <Button
                    component={LinkButton}
                    startIcon={<InfoIcon />}
                    to={joinedCampaignDetailsRoute.id}
                    params={{ uuid: item.uuid }}
                    variant="text"
                    color="primary"
                  >
                    {t('referralBot.joinedCampaigns.detailsButton')}
                  </Button>
                </MenuItem>
              </VertMenu>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider
            variant="middle"
            component="li"
            sx={{mt: 1, mb: 1}}
          />
        </Box>
      ))}
    </List>
  )
}
