import * as React from 'react'
import Chip from '@mui/material/Chip'
import { t } from 'i18next'
import { getChipColorForCampaignType } from '@components/ReferralBot/helpers'
import { CampaignType } from '@components/ReferralBot/Type/CampaignType'

interface Props {
  type: CampaignType
}

export const CampaignTypeLabel = ({type}: Props) => {

  const campaignType = t(`referralBot.campaign.shortType` + type).toLowerCase()

  return <Chip label={campaignType} color={getChipColorForCampaignType(type)} size="small" variant="outlined" />
}
