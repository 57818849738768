export enum CampaignStatus {
  INACTIVE = 1,
  ACTIVE = 2,
  FINISHED = 3,
  SCHEDULED = 4,
  START_ERROR = 5,
  CANCELLED = 6,
}

export const EDITABLE_STATUSES:CampaignStatus[] = [
  CampaignStatus.INACTIVE,
  CampaignStatus.ACTIVE,
  CampaignStatus.SCHEDULED,
] as const;

export const STATUSES_WHERE_PARTICIPANTS_IS_VISIBLE:CampaignStatus[] = [
  CampaignStatus.ACTIVE,
  CampaignStatus.FINISHED,
  CampaignStatus.CANCELLED,
] as const;
