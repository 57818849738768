import React, { useEffect } from 'react'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { fetchCampaign, pathContestCampaign } from '@framework/api/referral-bot.api'
import { useParams } from '@tanstack/react-router'
import {
  ownCampaignConnectBotRoute,
  ownCampaignEditContestBaseInfoBotRoute,
  ownCampaignSetBotUsernameRoute,
} from '@components/ReferralBot/OwnCampaign/router'
import { Box, Button } from '@mui/material'
import { LinkButton } from '@components/LinkButton'
import { getErrorMessage } from '@components/ReferralBot/helpers'
import { CampaignCreateStepper } from '@components/ReferralBot/OwnCampaign/Create/CampaignCreateStepper'
import { CampaignCreateStepperFooter } from '@components/ReferralBot/OwnCampaign/Create/CampaignCreateStepperFooter'
import { useAtom } from 'jotai/index'
import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import { CampaignStatus } from '@components/ReferralBot/Type/CampaignStatus'
import { CampaignType } from '@components/ReferralBot/Type/CampaignType'
import { BaseInfoForm, ContestBaseInfo } from '@components/ReferralBot/OwnCampaign/Create/Contest/BaseInfoForm'
import { DateTime } from 'luxon'

export const BaseInfoStep = () => {
  const { enqueueSnackbar } = useSnackbar()

  const {uuid} = useParams<typeof ownCampaignEditContestBaseInfoBotRoute>({
    from: ownCampaignEditContestBaseInfoBotRoute.id,
  })

  const { data: campaign, isLoading } = useQuery({
    queryKey: ['campaigns', uuid],
    queryFn: () => fetchCampaign(uuid),
  })
  const { mutate, isPending: isPatching } = useMutation({
    mutationKey: ['campaigns', uuid],
    mutationFn: pathContestCampaign,
  })
  const queryClient = useQueryClient()

  const [, setTitle] = useAtom(headerTitleAtom)

  useEffect(() => {
    setTitle(t('referralBot.ownCampaigns.configureCampaign', {campaignName: campaign?.name}) || '')

    return () => setTitle('')
  }, [campaign])

  const onSave = (payload: ContestBaseInfo) => {
    mutate(
      { ...payload, uuid },
      {
        onSuccess: async () => {
          enqueueSnackbar(t('referralBot.updated'), { variant: 'success' })
          await queryClient.invalidateQueries({ queryKey: ['campaigns'] })
          await queryClient.invalidateQueries({ queryKey: ['campaigns', uuid] })
        },
        onError: async (error) => {
          enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
        },
      }
    )
  }

  const hasData = Boolean(campaign?.name && campaign?.description)
  const disableNextStep = isLoading || isPatching || !hasData
  const disabledStatuses = [CampaignStatus.FINISHED, CampaignStatus.ACTIVE]

  return (
    <>
      {campaign && (
        <>
          <CampaignCreateStepper activeStep={'base_info'} campaign={campaign} />
          <BaseInfoForm
            loading={isPatching}
            onSave={onSave}
            disabled={disabledStatuses.includes(campaign.status)}
            data={{
              name: campaign.name || '',
              joinButtonText: campaign.joinButtonText || t('referralBot.newOwnCampaignForm.joinButtonTextDefault'),
              description: campaign.description || '',
              numberOfWinners: campaign.numberOfWinners || 1,
              startAt: campaign.startedAt
                ? campaign.startedAt
                : DateTime.now().plus({ hour: 1 }),
              finishAt: campaign.finishedAt
                ? campaign.finishedAt
                : DateTime.now().plus({ weeks: 1 }),
            }}
          />
          <CampaignCreateStepperFooter>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button
              sx={{ mr: 1 }}
              variant="contained"
              component={LinkButton}
              color="primary"
              to={campaign.type === CampaignType.BOT ? ownCampaignSetBotUsernameRoute.id : ownCampaignConnectBotRoute.id}
              params={{ uuid: campaign.uuid }}
              disabled={disableNextStep}
            >
              {t('referralBot.next')}
            </Button>
          </CampaignCreateStepperFooter>
        </>
      )}
    </>
  )
}
