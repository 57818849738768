import Box from '@mui/material/Box'
import { LinkButton } from '@components/LinkButton'
import { joinedCampaignListBotRoute } from '@components/ReferralBot/JoinedCampaign/router'
import { t } from 'i18next'
import { Button, Card, CardActions } from '@mui/material'
import React from 'react'
import { ownCampaignListBotRoute } from '@components/ReferralBot/OwnCampaign/router'
import { useAtom } from 'jotai/index'
import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import SettingsIcon from '@mui/icons-material/Settings'

export const HomeScreen = () => {
    const [, setTitle] = useAtom(headerTitleAtom)
    setTitle(t('referralBot.homePage.title'))

    return (
        <Box
            display="flex"
            flexDirection="column"
            // alignItems="center"
            sx={{mt: 4, mb: 4}}
        >
            <Card>
                <CardContent>
                    <Typography variant="h5" component="div">
                        <EmojiEventsIcon color="action" sx={{mr: 1}} />
                        {t('referralBot.joinedCampaigns.title')}
                    </Typography>
                    <Typography variant="body2">
                        {t('referralBot.homeScreen.joinedCampaignsExplanation')}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button
                      component={LinkButton}
                      startIcon={<NavigateNextIcon />}
                      to={joinedCampaignListBotRoute.id}
                      variant="text"
                      color="primary"
                    >
                        {t('referralBot.homeScreen.cta')}
                    </Button>
                </CardActions>
            </Card>
            <Card sx={{mt: 4}}>
                <CardContent>
                    <Typography variant="h5" component="div">
                        <SettingsIcon color="action" sx={{mr: 1}} />
                        {t('referralBot.ownCampaigns.title')}
                    </Typography>
                    <Typography variant="body2">
                        {t('referralBot.homeScreen.ownCampaignsExplanation')}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button
                      component={LinkButton}
                      startIcon={<NavigateNextIcon />}
                      to={ownCampaignListBotRoute.id}
                      variant="text"
                      color="primary"
                    >
                        {t('referralBot.homeScreen.cta')}
                    </Button>
                </CardActions>
            </Card>
        </Box>
    )
}
