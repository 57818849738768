import * as React from 'react'
import { t } from 'i18next'

import { CampaignDto } from '@components/ReferralBot/OwnCampaign/Type/CampaignDto'
import List from '@mui/material/List'
import { Button, Divider, ListItem, ListItemSecondaryAction } from '@mui/material'
import ListItemText from '@mui/material/ListItemText'
import SettingsIcon from '@mui/icons-material/Settings'
import Box from '@mui/material/Box'
import { VertMenu } from '@components/ReferralBot/VertMenu'
import MenuItem from '@mui/material/MenuItem'
import { LinkButton } from '@components/LinkButton'
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart'
import {
  ownCampaignEditBotRoute,
  ownCampaignEditContestBaseInfoBotRoute,
  ownCampaignStatusRoute,
} from '@components/ReferralBot/OwnCampaign/router'
import Typography from '@mui/material/Typography'
import { CampaignType } from '@components/ReferralBot/Type/CampaignType'
import { SecondaryDetails } from '@components/ReferralBot/OwnCampaign/List/SecondaryDetails'
import { isCampaignEditable } from '@components/ReferralBot/helpers'
import { CampaignTitle } from '@components/ReferralBot/OwnCampaign/List/CampaignTitle'

interface Props {
  campaigns: CampaignDto[]
}

export function ListAsTable({ campaigns }: Props) {
  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Box key="actions">
        <ListItem>
          <ListItemSecondaryAction>
            <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
              {t('referralBot.campaignsList.actions')}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>
      </Box>
      {campaigns.map((item) => (
        <Box key={item.uuid}>
          <ListItem>
            <ListItemText
              primary={<CampaignTitle campaign={item} />}
              secondary={<SecondaryDetails campaign={item} />}
            />
            <ListItemSecondaryAction>
              <VertMenu>
                {isCampaignEditable(item) && (
                  <MenuItem>
                    <Button
                      component={LinkButton}
                      startIcon={<SettingsIcon />}
                      to={item.type === CampaignType.CONTEST ? ownCampaignEditContestBaseInfoBotRoute.id : ownCampaignEditBotRoute.id}
                      params={{ uuid: item.uuid }}
                      variant="text"
                      color="primary"
                    >
                      {t('referralBot.campaignsList.change')}
                    </Button>
                  </MenuItem>
                )}
                <MenuItem>
                  <Button
                    component={LinkButton}
                    startIcon={<StackedLineChartIcon />}
                    to={ownCampaignStatusRoute.id}
                    params={{ uuid: item.uuid }}
                    variant="text"
                    color="primary"
                  >
                    {t('referralBot.status')}
                  </Button>
                </MenuItem>
              </VertMenu>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider
            variant="middle"
            component="li"
            sx={{mt: 1, mb: 1}}
          />
        </Box>
      ))}
    </List>
  )
}
