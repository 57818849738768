import * as React from 'react'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { t } from 'i18next'
import { TopActions } from '@components/ReferralBot/TopActions'
import { Button } from '@mui/material'
import { LinkButton } from '@components/LinkButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { ownCampaignListBotRoute } from '@components/ReferralBot/OwnCampaign/router'
import { CampaignDto } from '@components/ReferralBot/OwnCampaign/Type/CampaignDto'
import { CampaignType } from '@components/ReferralBot/Type/CampaignType'

type Steps = 'base_info' | 'connect_bot' | 'fund_campaign' | 'start_campaign';

interface CreateCampaignStep {
  title: string;
  step: Steps;
}

const steps: CreateCampaignStep[] = [
  { title: 'referralBot.createCampaignSteps.baseInfo', step: 'base_info' },
  { title: 'referralBot.createCampaignSteps.connectBot', step: 'connect_bot' },
  { title: 'referralBot.createCampaignSteps.fundCampaign', step: 'fund_campaign' },
  { title: 'referralBot.createCampaignSteps.startCampaign', step: 'start_campaign' },
];

interface Props {
  activeStep: Steps;
  campaign: CampaignDto;
}

export const CampaignCreateStepper = ({activeStep, campaign}: Props) => {
  const hiddenSteps: Steps[] = [];

  if (campaign.type === CampaignType.CONTEST) {
    hiddenSteps.push('fund_campaign');
  }

  const filteredSteps = steps.filter(s => !(hiddenSteps || []).includes(s.step))
  const activeStepIndex = filteredSteps.findIndex(s => s.step === activeStep);

    return (
      <>
        <TopActions>
          <Button
            component={LinkButton}
            startIcon={<ArrowBackIcon />}
            to={ownCampaignListBotRoute.id}
            variant="outlined"
            color="primary"
          >
            {t('referralBot.createCampaignSteps.backToCampaigns')}
          </Button>
        </TopActions>
        <Stepper activeStep={activeStepIndex} sx={{pt: 2, pb: 8}} alternativeLabel>
          {filteredSteps.map((step, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};

            return (
              <Step key={index} {...stepProps}>
                <StepLabel {...labelProps}>
                  {t(step.title)}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </>
    );
}
