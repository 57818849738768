import { CampaignDto } from '@components/ReferralBot/OwnCampaign/Type/CampaignDto'
import * as React from 'react'
import { isCampaignActive } from '@components/ReferralBot/helpers'
import { CampaignTypeLabel } from '@components/ReferralBot/Campaign/CampaignTypeLabel'

interface Props {
  campaign: CampaignDto
}

export const CampaignTitle = ({campaign}: Props) => {
  return (
    <>
      {isCampaignActive(campaign.status) && <b>{campaign.name}</b>}
      {!isCampaignActive(campaign.status) && (<>{campaign.name}</>)}
      {' '}
      <CampaignTypeLabel type={campaign.type} />
    </>
  )
}
