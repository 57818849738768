import React, { useEffect } from 'react'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'

import { useParams } from '@tanstack/react-router'
import {
  ownCampaignConnectBotRoute,
  ownCampaignEditBotRoute, ownCampaignEditContestBaseInfoBotRoute,
  ownCampaignFundRoute, ownCampaignStartRoute,
} from '@components/ReferralBot/OwnCampaign/router'
import { Alert, Button, Step, StepContent, StepLabel, Stepper, TextField } from '@mui/material'
import { LinkButton } from '@components/LinkButton'
import Box from '@mui/material/Box'
import { LoadingButton } from '@mui/lab'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { useQuery } from '@tanstack/react-query'
import { fetchCampaign, fetchCampaignConnectCode } from '@framework/api/referral-bot.api'
import CheckIcon from '@mui/icons-material/Check'
import { CampaignCreateStepper } from '@components/ReferralBot/OwnCampaign/Create/CampaignCreateStepper'
import { CampaignCreateStepperFooter } from '@components/ReferralBot/OwnCampaign/Create/CampaignCreateStepperFooter'
import { useAtom } from 'jotai/index'
import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import { isContest } from '@components/ReferralBot/helpers'

export const ConnectBot = () => {
  const { enqueueSnackbar } = useSnackbar()
  const {uuid} = useParams<typeof ownCampaignConnectBotRoute>({
    from: ownCampaignConnectBotRoute.id
  })
  const {
    data: campaign,
    isLoading: isLoadingCampaign,
    isRefetching,
    refetch,
  } = useQuery({
    queryKey: ['campaigns', uuid],
    queryFn: () => fetchCampaign(uuid),
  })
  const { data: campaignConnectCode } = useQuery({
    queryKey: ['campaigns', 'connect_code', uuid],
    queryFn: () => fetchCampaignConnectCode(uuid),
  })

  const [, setTitle] = useAtom(headerTitleAtom)

  useEffect(() => {
    setTitle(t('referralBot.ownCampaigns.configureCampaign', {campaignName: campaign?.name}) || '')

    return () => setTitle('')
  }, [campaign])

  const handleCheckConnection = async () => {
    try {
      const refetchResult = await refetch()

      if (!refetchResult?.data?.isBotConnected) {
        enqueueSnackbar(t('referralBot.connectBot.botIsNotConnected'), { variant: 'warning' })
      }
    } catch (e) {
      enqueueSnackbar(t('error'), { variant: 'error' })
    }
  }

  // todo change it?
  const botName = '@InviteUpBot'

  return (
    <>
      {campaign && (
        <CampaignCreateStepper activeStep={'connect_bot'} campaign={campaign} />
      )}
      {campaign?.isBotConnected && (
        <Box
          mt={2}
          mb={2}
        >
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
          >
            {t('referralBot.connectBot.botIsConnected')}
          </Alert>
        </Box>
      )}
      <Stepper
        activeStep={-1}
        orientation="vertical"
      >
        <Step
          key={1}
          expanded
          active
        >
          <StepLabel>{t('referralBot.connectBot.step1')}</StepLabel>
          <StepContent>
            <Box sx={{ mb: 2 }}>{t('referralBot.connectBot.step1Explanation', { bot: botName })}</Box>
            <Box sx={{
              mt: 2,
              color: 'text.secondary',
              fontWeight: 'bold',
            }}>
              {t('referralBot.connectBot.step1Explanation2')}
            </Box>
          </StepContent>
        </Step>
        <Step
          key={2}
          expanded
          active
        >
          <StepLabel>{t('referralBot.connectBot.step2')}</StepLabel>
          <StepContent hidden={false}>
            <Box sx={{ mb: 2 }}>
              {t('referralBot.connectBot.step2Explanation')}
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                label=""
                variant="outlined"
                placeholder="Loading..."
                value={campaignConnectCode?.connectCommand || ''}
                onFocus={(event) => {
                  event.target.select()
                }}
              />
              <Box sx={{
                mt: 2,
                color: 'text.secondary',
              }}>
                {t('referralBot.connectBot.step2Explanation2')}
              </Box>
            </Box>
          </StepContent>
        </Step>
        <Step
          key={3}
          expanded
          active
        >
          <StepLabel>{t('referralBot.connectBot.step3')}</StepLabel>
          <StepContent>
            <Box sx={{ mb: 2 }}>
              <Box sx={{ mb: 2 }}>{t('referralBot.connectBot.step3Explanation')}</Box>
              <Box
                display="flex"
                justifyContent="center"
              >
                <LoadingButton
                  loading={isLoadingCampaign || isRefetching}
                  loadingPosition="start"
                  startIcon={<CheckCircleOutlineIcon />}
                  variant="contained"
                  color="success"
                  disabled={campaign?.isBotConnected}
                  onClick={handleCheckConnection}
                >
                  {t('referralBot.connectBot.checkConnection')}
                </LoadingButton>
              </Box>
            </Box>
          </StepContent>
        </Step>
      </Stepper>
      {campaign && (
        <CampaignCreateStepperFooter>
          <Button
            color="inherit"
            sx={{ mr: 1 }}
            component={LinkButton}
            to={isContest(campaign) ? ownCampaignEditContestBaseInfoBotRoute.id : ownCampaignEditBotRoute.id}
            params={{ uuid: campaign.uuid }}
          >
            {t('referralBot.back')}
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button
            sx={{ mr: 1 }}
            variant="contained"
            component={LinkButton}
            color="primary"
            to={isContest(campaign) ? ownCampaignStartRoute.id : ownCampaignFundRoute.id}
            params={{ uuid: campaign.uuid }}
            disabled={!campaign.isBotConnected}
          >
            {t('referralBot.next')}
          </Button>
        </CampaignCreateStepperFooter>
      )}
    </>
  )
}
